import React from "react";
import { graphql } from "gatsby";
import MdxProvider from "@/components/MDXProvider";
import { MDXRenderer } from "gatsby-plugin-mdx";
import Layout from "@/components/Layout";
import { ArticleJsonLd, GatsbySeo } from "gatsby-plugin-next-seo";
import ListNote from "@/components/ListNote";
import Link from "@/components/GatsbyLink";
import kebabCase from "lodash.kebabcase";
import {
  SITE_TITLE,
  SITE_DESCRIPTION,
  url,
  LOCALE,
} from "../../config";

const PostTemplate = ({ data }) => {
  const postNode = data.mdx;
  const post = data.mdx.fields;
  const { tags, category } = postNode.frontmatter;
  const { edges } = data.allMdx;

  return (
  <>
    <GatsbySeo
      title={`${post.title} - ${SITE_TITLE}`}
      description={postNode.excerpt}
      canonical={`${url}${post.slug}`}
      language={LOCALE}
      openGraph={{
        url: `${url}${post.slug}`,
        title: `${post.title} - ${SITE_TITLE}`,
        description: postNode.excerpt,
        type: 'article',
        article: {
          publishedTime: post.date,
          modifiedTime: post.date,
          authors: [`${url}ilham-maulana`],
        },
        images: [
          {
            url: post.images,
            alt: post.title,
          },
        ],
        site_name: 'Fandomnesia',
      }}
      twitter={{
        handle: '@fandomnesia_com',
        site: '@fandomnesia',
        cardType: 'summary_large_image',
      }}
    />
    <MdxProvider>
      <Layout>
        <article className="my-6 prose prose-green lg:prose-xl">
          <h1>{post.title}</h1>
          <small className="text-sm font-bold uppercase tracking-tight">
            {post.date}
          </small>
          <MDXRenderer>{postNode.body}</MDXRenderer>
        </article>
        <h5 className="uppercase font-bold mb-4">Topics</h5>
        <ul className="flex flex-wrap gap-4 mb-4">
          {tags &&
            tags.map((tag) => (
              <li key={tag} className="px-2 py-1 bg-slate-100 rounded-full">
                <Link to={`/tag/${kebabCase(tag)}`}>{tag}</Link>
              </li>
            ))}
        </ul>
        <h5 className="uppercase font-bold mb-4">More Post</h5>
        <ListNote edges={edges}/>
      </Layout>

    <ArticleJsonLd
      url={`${url}${post.slug}`}
      headline={post.title}
      images={post.images}
      keywords={tags}
      datePublished={post.date}
      dateModified={post.date}
      overrides={{
        '@type': 'Article',
        'author': {
          '@type': 'Person',
          'name': 'Ilham Maulana',
          'url': 'https://www.fandomnesia.com/Ilham-maulana'
          },
      }}
      publisherName="Fandomnesia"
      publisherLogo="https://www.fandomnesia.com/icons/icon-144x144.png"
      description={postNode.excerpt}
    />
    </MdxProvider>
  </>
  );
};

export const query = graphql`
  query PostBySlug($slug: String!) {
    mdx(fields: { slug: { eq: $slug } }) {
      ...post
    }
    allMdx(
      filter: {
        frontmatter: { publish: { ne: false } }
        fields: { slug: { ne: $slug } }
        fileAbsolutePath: { regex: "/vault/" }
      }
      limit: 10
      sort: { order: DESC, fields: frontmatter___date }
    ) {
      edges {
        node {
          ...postList
        }
      }
    }
  }
`;

export default PostTemplate;
